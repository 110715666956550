import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from "axios"
import { getApiRoot } from '@helpers/api'
import { getKeycloak } from '@/helpers/keycloak';

export const InspectionStatuses = {
    Requested: 0,
    Approved: 1,
    Rejected: 2,
    Planned: 3
}

export const NetOperatorStatuses = {
    Requested: 0,
    Completed: 1
}

export const useInspectionStore = defineStore('inspectionStore', () => {
    const inspection = ref();

    async function getInspectionByID(id, source){
        let url = getApiRoot();
        switch(source) {
            case 'inspection':
                url += '/sales/inspections/' + id + '/?lang=nl-BE&relatedData=articles';
                break;
            case 'order':
                url += '/sales/orders/' + id + '/?lang=nl-BE&relatedData=articles,Inspection&$select=articles,Inspection';
                break;
            case 'invoice':
                url += '/sales/invoices/' + id + '/?lang=nl-BE&relatedData=articles,Inspection&$select=articles,Inspection';
                break;
        };
        try{
            const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
            const response = await axios.get(url, { headers });
            if(response.data.errors.length == 0){
                inspection.value = responseToInspection(response.data.data[0], source);
             }
             else{
                 throw new Error(response.data.errors)
             }
        } catch(error){
            throw new Error(error);
        }
    }

    function responseToInspection(response, source) {
        let inspection = {};
        switch(source) {
            case 'inspection':
                inspection = response;
                break;
            case 'order':
            case 'invoice':
                const responseInspection = response.Inspection[0];
                inspection.KeurderJobID = responseInspection.KeurderJobID;
                inspection.NetOperatorStatus = responseInspection.NetOperatorStatus;
                inspection.NetOperatorDate = responseInspection.NetOperatorDate;
                inspection.InspectionDate = responseInspection.KeuringAppointmentDate;
                inspection.ApprovedDate = responseInspection.ApprovedDate;

                inspection.Status = InspectionStatuses.Requested;
                if(responseInspection.AskedKeuring == 1){
                    inspection.Status = InspectionStatuses.Requested;
                }
                if(responseInspection.KeuringAppointment == 1) {
                    inspection.Status = InspectionStatuses.Planned;
                }
                if(responseInspection.Approved == 1) {
                    inspection.Status = InspectionStatuses.Approved;
                }
                inspection.articles = response.articles;
                break;
        };
        return inspection;
    }


    return {
        inspection,
        getInspectionByID
    };
})
