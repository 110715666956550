import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from 'axios'
import { getApiRoot, getApiUrl } from '@/helpers/api'
import { useOfferStore } from '@/store/offers'
import { useOrderStore } from '@/store/orders'
import { useDeliveryStore } from '@/store/deliveries'
import { InvoiceStatuses } from '@/store/invoices'
import { InspectionStatuses, NetOperatorStatuses } from '@/store/inspections'
import {
  getLocalstorageItem,
  localStorageKeys,
  removeLocalstorageItem,
  setLocalstorageItem
} from '@/helpers/localStorage'
import { useUserStore } from '@/store/user';
import { getKeycloak } from '@/helpers/keycloak';
import { getSubdomain } from '@/helpers/util';

export const InstallationStatuses = {
    New: 1,
    Signed: 2,
    Prepaid: 3,
    Planned: 4,
    Paid: 5,
    InspectionPlanned: 6,
    NetOperator: 7,
    Completed: 8,
}

export const useInstallationStore = defineStore('installationStore', () => {
    const installations = ref([]);
    const offerStore = useOfferStore();
    const orderStore = useOrderStore();
    const deliveryStore = useDeliveryStore();
    let { getFinalOfferStatus } = offerStore;
    let { getFinalOrderStatus } = orderStore;
    let { getFinalDeliveryStatus } = deliveryStore;


    async function setInstallationsStatus(installations){
        const FinalOfferStatus = await getFinalOfferStatus();
        const FinalOrderStatus = await getFinalOrderStatus();
        const FinalDeliveryStatus = await getFinalDeliveryStatus();

        installations.forEach((installation, index) => {
            installation.id = index;
            if(installation.OfferID > 0){
                if(installation.OfferStatus == FinalOfferStatus){
                    installation.status = InstallationStatuses.Signed;
                }
                else{
                    installation.status = InstallationStatuses.New;
                }
            }

            if(installation.OrderID > 0) {
                if(installation.OrderStatus == FinalOrderStatus){
                    installation.status = InstallationStatuses.Prepaid;
                }
                else{
                    installation.status = InstallationStatuses.Signed;
                }
            }

            if(installation.Order_DeliveriesID > 0){
                if(installation.DeliveryStatus == FinalDeliveryStatus){
                    installation.status = InstallationStatuses.Planned;
                }
                else{
                    installation.status = InstallationStatuses.Prepaid;
                }
            }

            if(installation.InvoiceID > 0 && installation.DeliveryStatus == FinalDeliveryStatus){
                if(installation.InvoiceStatus == InvoiceStatuses.Paid){
                    installation.status = InstallationStatuses.Paid;
                }
                else{
                    installation.status = InstallationStatuses.Planned;
                }
            }

            if((installation.InspectionHistoryID > 0 || Number(installation.OrderInspectionJobID) > 0 || Number(installation.InvoiceInspectionJobID) > 0)
                && installation.InvoiceStatus == InvoiceStatuses.Paid
                ){
                installation.inspectionOnOrder = installation.OrderInspectionJobID > 0;
                installation.inspectionOnInvoice = installation.InvoiceInspectionJobID > 0;
                installation.inspectionOnHistory = installation.InspectionHistoryID > 0;

                if(installation.inspectionOnHistory && installation.InspectionStatus == InspectionStatuses.Approved) {
                    installation.status = InstallationStatuses.NetOperator;
                }
                else if(installation.inspectionOnInvoice && installation.InvoiceInspectionApproved == InspectionStatuses.Approved) {
                    installation.status = InstallationStatuses.NetOperator;
                }
                else if(installation.inspectionOnOrder && installation.OrderInspectionApproved == InspectionStatuses.Approved) {
                    installation.status = InstallationStatuses.NetOperator;
                }
                else{
                    installation.status = InstallationStatuses.InspectionPlanned;
                }
            }

            if(installation.NetOperatorStatus === NetOperatorStatuses.Completed) {
                installation.status = InstallationStatuses.Completed;
            }
        });
        return installations;
    }

    async function getInstallations() {
        const localStorageInstallations = getLocalstorageItem(localStorageKeys.installations);
        const { user } = useUserStore();

        if (localStorageInstallations) {
            installations.value = localStorageInstallations;
            return;
        }

        try {
            const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
            const response = await axios.get(
                `${getApiUrl('management/customers', user.id)}/installations/?lang=nl-BE`,
                { headers }
            )
            if (response.data.errors.length > 0) {
                throw new Error(response.data.errors)
            }
            let reponseInstallations = await setInstallationsStatus(response.data.data[0].installations);
            reponseInstallations = await getInstallationsArticles(reponseInstallations);
            installations.value = reponseInstallations;
            setLocalstorageItem(localStorageKeys.installations, reponseInstallations);
        } catch (error) {
            throw new Error(error);
        }
    }

    async function getInstallationsArticles(_installations){
        const _installationsWithArticles = await Promise.all(_installations.map(async (installation) => {
            const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
            const response = await axios.get(
                `${getApiRoot()}/sales/offers/${installation.OfferID}/articles/?lang=nl-BE`,
                { headers }
            );
            if(response.data.errors.length > 0) {
                throw new Error(response.data.errors);
            }
            return {
                ...installation,
                articles: response.data.data[0].articles
            }
        }))
        return _installationsWithArticles;
    }

    async function refreshInstallations(){
        removeLocalstorageItem(localStorageKeys.installations);
        await getInstallations();
    }

    async function getInstallationByID(id){
        await getInstallations();
        return installations.value.find((installation) => installation.id == id);
    }

    async function getInstallationOffer(id){
        await getInstallations();
        const installation = installations.value.find((installation) => installation.id == id);
        return {
            OfferID: installation.OfferID == undefined ? NaN : installation.OfferID,
            CurrentStatusID: installation.OfferStatus,
            OfferVersionNumbers: installation.OfferVersionNumbers,
            OfferActiveVersionNumber: installation.VersionNumber,
            Offer_TypeID: installation.Offer_TypeID,
        }
    }

    async function getInstallationOrder(id){
        await getInstallations();
        const installation = installations.value.find((installation) => installation.id == id);
        return {
            OrderID: installation.OrderID == undefined ? NaN : installation.OrderID,
            CurrentStatusID: installation.OrderStatus,
            PrepaymentID: installation.PrepaymentInvoiceID,
            PrepaymentStatus: installation.PrepaymentInvoiceStatus
        }
    }

    async function getInstallationDelivery(id){
        await getInstallations();
        const installation = installations.value.find((installation) => installation.id == id);
        return {
            Order_DeliveriesID: installation.Order_DeliveriesID == undefined ? NaN : installation.Order_DeliveriesID,
            CurrentStatusID: installation.DeliveryStatus
        }
    }

    async function getInstallationInvoice(id){
        await getInstallations();
        const installation = installations.value.find((installation) => installation.id == id);
        return {
            InvoiceID: installation.InvoiceID == undefined ? NaN : installation.InvoiceID,
            CurrentStatusID: installation.InvoiceStatus
        }
    }

    async function getInstallationInspection(id){
        await getInstallations();
        const installation = installations.value.find((installation) => installation.id == id);

        const inspectionID = installation.inspectionOnOrder ? installation.OrderID :
                                installation.inspectionOnInvoice ? installation.InvoiceID :
                                    installation.inspectionOnHistory ? installation.InspectionHistoryID : NaN;

        const inspectionSource = installation.inspectionOnOrder ? 'order' :
                                    installation.inspectionOnInvoice ? 'invoice' :
                                        installation.inspectionOnHistory ? 'inspection' : '';

        let inspectionStatus = InspectionStatuses.Requested;

        if(installation.inspectionOnHistory) {
            inspectionStatus = installation.InspectionStatus;
        }
        else if(installation.inspectionOnInvoice && installation.InvoiceInspectionApproved == InspectionStatuses.Approved) {
            inspectionStatus = InspectionStatuses.Approved;
        }
        else if(installation.inspectionOnOrder && installation.OrderInspectionApproved == InspectionStatuses.Approved) {
            inspectionStatus = InspectionStatuses.Approved;
        }

        const NetOperatorStatus = installation.NetOperatorStatus;

        return {
            InspectionID: inspectionID,
            InspectionSource: inspectionSource,
            InspectionStatus: inspectionStatus,
            NetOperatorStatus: NetOperatorStatus,
        }
    }

    return {
        installations,
        refreshInstallations,
        getInstallations,
        getInstallationByID,
        getInstallationOffer,
        getInstallationOrder,
        getInstallationDelivery,
        getInstallationInvoice,
        getInstallationInspection
    }
})
