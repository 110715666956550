<template>
  <v-app>
    <v-main :style="{background: $vuetify.theme.themes['lightTheme'].base0}">
      <v-theme-provider>

        <NavBarTop v-if="route.path !== '/'"></NavBarTop>

        <router-view />

        <NavBarSide v-if="route.path !== '/' && userLoggedIn && !mobile"></NavBarSide>

        <NavBarBottom v-if="displayBottomNavBar && mobile"></NavBarBottom>

        <SnackBar></SnackBar>

      </v-theme-provider>
    </v-main>
  </v-app>
</template>

<script setup>
import NavBarTop from '@components/NavBarTop.vue'
import NavBarBottom from '@components/NavBarBottom.vue'
import NavBarSide from '@components/NavBarSide.vue'
import SnackBar from '@components/SnackBar.vue'
import { useRoute } from 'vue-router'
import { onMounted, watchEffect, ref } from 'vue'
import { 
  emptyLocalstorage,
  hasToken
} from '@helpers/localStorage'
import { hasSubdomain } from './helpers/util'
import { useUserStore } from '@store/user'
import { useInstallationStore } from '@store/installations'
import { useOfferStore } from '@store/offers'
import { useOrderStore } from '@store/orders'
import { useDeliveryStore } from '@store/deliveries'
import { useArticleStore } from '@store/articles'
import { storeToRefs } from 'pinia'
import { useDisplay } from 'vuetify'
import { useI18n } from 'vue-i18n'
import { useRepairsStore } from './store/repairs'
import { useIssueStore } from './store/issues'

const displayBottomNavBar = true;
const route = useRoute();
const { mobile } = useDisplay();

const installationStore = useInstallationStore();
const { refreshInstallations } = installationStore;

const offerStore = useOfferStore();
const { getOfferStatuses } = offerStore;

const orderStore = useOrderStore();
const { getOrderStatuses } = orderStore;

const deliveryStore = useDeliveryStore();
const { getDeliveryStatuses } = deliveryStore;

const repairsStore = useRepairsStore();
const { getRepairStatuses } = repairsStore;

const issueStore = useIssueStore();
const { getIssueStatuses } = issueStore;

const articleStore = useArticleStore();
const { getArticleGroupTypes } = articleStore;

const userStore = useUserStore();
const { userLoggedIn } = storeToRefs(userStore);

const i18n = useI18n();
const language = ref(route.query?.lang); // initialize variable with change tracking capabilities from home?lang=en

watchEffect(() => {
   language.value = route.query?.lang;
   if(i18n.availableLocales.includes(language.value)){
      i18n.locale.value = language.value;
   }
})

onMounted(async () => {
  try{
    if(hasSubdomain()){
      await getOfferStatuses();
      await getOrderStatuses();
      await getDeliveryStatuses();
      await getRepairStatuses();
      await getIssueStatuses();
      await getArticleGroupTypes();
      if(hasToken()){
        userLoggedIn.value = true;
        await refreshInstallations();
      }
    }
    else{
      emptyLocalstorage();
    }
  }catch(error){
    console.log(error)
  }
})

</script>